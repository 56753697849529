import { withTranslations } from 'src/i18n/static-generation';
import MatchView from 'src/views/Match';
export const getStaticProps = withTranslations(() => ({
  props: {
    canonical: true,
    metas: [{
      property: 'og:title',
      content: 'OG_TITLE',
      key: 'og:title'
    }, {
      property: 'og:description',
      content: 'OG_DESC',
      key: 'og:description'
    }, {
      name: 'description',
      content: 'OG_DESC',
      key: 'description'
    }, {
      property: 'og:image',
      content: '/images/og/azarOgImage.png',
      key: 'og:image'
    }]
  }
}), {
  xDefaultRouteFn: () => ({
    pathname: '/'
  })
});
export default MatchView;